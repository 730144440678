.IonNote-wrapper {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.IonNote-wrapper--NoResults {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
