.details-container {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.details-container img {
    width: 100%;
    height: auto;
}

.details-container .btn {
    position: absolute;
}

.details-container .back {
    top: 3%;
    left: 5%;
}

.details-container .favorite {
    top: 3%;
    right: 5%;
}

.details-container h2 {
    font-size: large;
}

.details-container .name-shareBtn-section {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
