.member {
    border-radius: 100%;
}

.member-card {
    text-align: center;
    width: 100;
    margin-left: 16px;
    margin-right: 16px;
    display: inline-block;
}

.scroll-container {
    height: 250px;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
}
